<template>
  <div class="main-container">
    <v-card class="main-card">
      <img class="logo" src="https://cdn.tsilink.com/images/tsi_link_logo.png" />
      <authenticate-content v-if="!authenticated" @authenticated="authenticated = true" />
      <telemetry-content :authenticated="authenticated" v-else />
    </v-card>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import AuthenticateContent from '@/components/AuthenticateContent.vue'
import TelemetryContent from '@/components/TelemetryContent.vue'

export default defineComponent({
  name: 'PublicHomePage',
  components: {
    AuthenticateContent,
    TelemetryContent
  },
  setup() {
    const authenticated = ref(false)

    return {
      authenticated
    }
  }
})
</script>

<style scoped lang="scss">
.main-container {
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 100%;
  overflow: auto;

  .main-card {
    width: 500px;
    padding: 32px;

    @media (max-width: 599px) {
      height: 100%;
      width: 100%;
      padding: 32px;
      justify-content: flex-start;
    }

    .logo {
      display: block;
      margin-left: auto;
      margin-right: auto;
      height: 64px;
      margin-bottom: 32px;
    }

    .v-card__title {
      padding: 0px 0px 16px 0px;
      font-weight: 600;

      @media (max-width: 599px) {
        margin-top: calc(15vh - 56px);
      }
    }

    .v-card__subtitle {
      padding: 0px;
      font-size: 1.25rem;
    }
  }
}
</style>
