import { createPinia } from 'pinia'
import { createApp } from 'vue'

// I18n
import { i18n } from '@/plugins/i18n'

// Vuetify
import { vuetify } from '@/plugins/vuetify'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import VueGtag from "vue-gtag";

// for supporting tableau old browser version https://github.com/vuetifyjs/vuetify/issues/15882
import 'core-js/features/array/at'


// Fusion Auth

import App from '@/App.vue'
import { getRouter } from '@/router'
import { setupTableau } from './services/setupTableau'

setupTableau()
const setup = async () => {
  const app = createApp(App)
  const router = await getRouter(app)
  app.use(VueGtag, {  config: { id: import.meta.env.VITE_GA_ID} });
  app.use(router)
  app.use(i18n)
  app.use(vuetify)
  app.use(createPinia())
  app.mount('#app')
}

setup()
