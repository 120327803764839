import { defineStore } from 'pinia'
import axios from 'axios'
import { isOkResponse } from '@/services/utils'
import { i18nInstance } from '@/plugins/i18n'

export const useWDCStore = defineStore('main', {
  state: () => {
    return {
      // CHANGED: Initialize oAuthErrorMessage as an empty string instead of null
      oAuthErrorMessage: '' as string, // Avoids null-related proxy issues when setting this later
      devices: null as any[] | null,
      telemetry: null as any[] | null
    }
  },
  actions: {
    async getOAuthToken({ clientCredentials, tableau }: { clientCredentials: any; tableau: any }) {
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        params: {
          grant_type: 'client_credentials'
        }
      }
      const urlencoded = new URLSearchParams()
      urlencoded.append('client_id', clientCredentials.id)
      urlencoded.append('client_secret', clientCredentials.secret)

      try {
        const response = await axios.post(
          `${import.meta.env.VITE_APIGEE_HOST}/oauth/client_credential/accesstoken`,
          urlencoded,
          config
        )
        if (isOkResponse(response.status)) {
          const passwordObject: any = {}
          passwordObject.token = 'Bearer ' + response.data.access_token
          passwordObject.expiration = new Date(Date.now() + response.data.expires_in * 1000)

          tableau.password = JSON.stringify(passwordObject)

          this.resetOAuthErrorMessage() // Keeps the call to reset the error message after success
        } else {
          this.handleOAuthError(response.status) // Use a helper method to handle errors (moved into a separate method)
        }
      } catch (error: any) {
        this.handleOAuthError(error.response.status)
      }
    },
    async getDevices(tableau: any) {
      const passwordObject = JSON.parse(tableau.password)
      const config = {
        headers: {
          Authorization: passwordObject.token,
          Accept: 'application/json',
          'X-TSI-User-Agent': 'TableauWDC'
        }
      }

      try {
        const response = await axios.get(
          `${import.meta.env.VITE_APIGEE_HOST}${import.meta.env.VITE_APIGEE_ENV}/api/v3/external/devices`,
          config
        )
        if (isOkResponse(response.status)) {
          this.updateDevices(response.data)
        } else {
          // CHANGED: Added a basic error handler for devices fetching
          this.handleError('Error fetching devices') // Logs the error or could implement more logic
        }
      } catch (error: any) {
        this.handleError(error.message)
      }
    },
    updateDevices(devices: any[]) {
      this.devices = devices
    },
    updateTelemetry(telemetry: any[]) {
      this.telemetry = telemetry
    },
    // CHANGED: resetOAuthErrorMessage initializes error to an empty string instead of null
    resetOAuthErrorMessage() {
      this.oAuthErrorMessage = '' // Set error message to empty string, not null
    },
    // CHANGED: New helper method to handle OAuth errors, includes logging
    handleOAuthError(status: number) {
      let errorMessage = '' // Initialize as an empty string
      switch (status) {
        case 403:
          errorMessage = i18nInstance.t('publicHome.oAuthErrors.403')
          break
        case 429:
          errorMessage = i18nInstance.t('publicHome.oAuthErrors.429')
          break
        default:
          errorMessage = i18nInstance.t('publicHome.oAuthErrors.default')
      }
      console.error('OAuth Error Message:', errorMessage) // Log the message to ensure it's a valid string
      this.oAuthErrorMessage = errorMessage // Set the state properly after confirming it's a string
    },
    // CHANGED: Added a generic error handling method for other parts of the code
    handleError(message: string) {
      console.error(message) // Log the error
    }
  },
  getters: {}
})
