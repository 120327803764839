<template>
  <div class="full-width">
    <v-card-text>
      <v-text-field
        :type="clientCredentials.type"
        v-bind:label="t('publicHome.clientCredentials.id')"
        v-model="clientCredentials.id"
        maxlength="255"
        color="primary"
      />
      <br />
      <v-text-field
        :type="clientCredentials.type"
        v-bind:label="t('publicHome.clientCredentials.secret')"
        v-model="clientCredentials.secret"
        maxlength="255"
        color="primary"
      />
    </v-card-text>

    <v-btn
      block
      @click="authenticate"
      class="bg-primary authButton"
      :disabled="disableAuthenticateButton">
      {{ t("publicHome.clientCredentials.authenticate") }}
    </v-btn>

    <div v-if="oAuthErrorMessage !== null" class="error-text">
      {{ oAuthErrorMessage }}
    </div>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent, computed, onMounted } from 'vue';
import { useWDCStore } from '@/store/index';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'AuthenticateContent',
  emits: ['authenticated'],
  setup(_, { emit }) {
    const wdcStore = useWDCStore();
    const oAuthErrorMessage = computed(() => wdcStore.oAuthErrorMessage)
    const { t } = useI18n();

    const clientCredentials = ref({
      type: 'text',
      id: '',
      secret: ''
    });

    // Helper function to validate if OAuth token is valid
    const hasValidOAuthToken = () => {
      let token, expiration;
      if (window.tableau.password) {
        try {
          const passwordObject = JSON.parse(window.tableau.password);
          token = passwordObject.token;
          expiration = passwordObject.expiration;
        } catch (err) {
          return false;
        }
      }
      return (
        token &&
        token.length &&
        token.startsWith('Bearer') &&
        new Date(expiration) > new Date()
      );
    };

    // Authenticate using the client credentials and store the token
    const authenticate = async () => {
      await wdcStore.getOAuthToken({
        clientCredentials: clientCredentials.value,
        tableau: window.tableau
      });
      clientCredentials.value.id = '';
      clientCredentials.value.secret = '';

      if (oAuthErrorMessage.value === '') {
        emit('authenticated');
      }
    };

    // Disable the authenticate button if credentials are empty
    const disableAuthenticateButton = computed(() => {
      return clientCredentials.value.id === '' || clientCredentials.value.secret === '';
    });

    // Auto authenticate if valid OAuth token exists
    onMounted(() => {
      if (hasValidOAuthToken()) {
        emit('authenticated');
      }
    });

    return {
      clientCredentials,
      authenticate,
      oAuthErrorMessage,
      disableAuthenticateButton,
      hasValidOAuthToken,
      t
    };
  }
});
</script>

<style scoped lang="scss">
@import '@/assets/shared.scss';

.error-text {
  font-size: 14px;
}
</style>
