import PublicHomeView from '@/views/PublicHome.vue'
import axios from 'axios'
import { type App, type Component } from 'vue'
import { createRouter, createWebHistory, type RouteRecordRaw, type Router } from 'vue-router'

let _router: Router

const enum LocalStoreKeys {
  Navigation = 'navigation'
}

const componentsMetaMaping: Record<string, string> = {
  'routes.homePage': 'PublicHomePage'
}
/**
 * Register all navigation components required to properly render the router.
 * @param app The Vue application instance.
 * @returns
 */
function registerComponents(app: App): Record<string, Component<any>> {
  app.component('PublicHomePage', PublicHomeView)

  return app._context.components
}

/**
 * Gets the router dynamically using data from the database.
 * @param app The Vue application instance.
 * @param auth0 The Auth0 plugin instance.
 * @returns
 */
const _initializeRouter = async (app: App<Element>): Promise<Router> => {
  const applicationId = APP_ENV.VITE_APPLICATION_ID
  let results

  // Cache the navigation, If it ever fails pull from the cache.
  try {
    results = await axios.get(
      `${APP_ENV.VITE_APPLICATIONS_HOST}/applications/${applicationId}/navigation`
    )
    localStorage.setItem(LocalStoreKeys.Navigation, JSON.stringify(results.data))
  } catch {
    const data = localStorage.getItem(LocalStoreKeys.Navigation)
    if (data) {
      results = { status: 200, data: JSON.parse(data) }
    } else {
      results = { status: 500 }
    }
  }

  console.info('results: ', results)

  const components = registerComponents(app)
  const routes: Array<RouteRecordRaw> = []

  if (results.status >= 200 && results.status <= 299) {
    results.data.forEach((route: RouteRecordRaw) => {

      if (route.meta) {
        const componentsName = componentsMetaMaping[route.meta.title]
        const component: Component = components[componentsName]
        if (component) {
          route.component = component
          route.meta.routePermissions.alwaysVisible = true
          routes.push(route)
        } else {
          console.error(
            `Router - missing component name in meta field: ${componentsName}`
          )
        }
      } else {
        console.error(`Router - missing component name, route was skipped.`)
      }
    })
  } else {
    console.error('Failed to load navigation routes: ', results.statusText)
  }
    _router = createRouter({
    history: createWebHistory(APP_ENV.VITE_PUBLIC_PATH),
    routes
  })

  return _router
}

/**
 * Ensure the router is created only once.
 * @param app
 * @returns
 */
export const getRouter = async (app: App<Element>): Promise<Router> => {
  if (_router) {
    return _router
  }
  return _initializeRouter(app)
}
