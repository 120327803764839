<template>
  <div class="full-width">
    <v-card-text>
      <!-- device selection -->
      <v-select :items="devices" item-title="metadata.friendlyName" item-value="device_id" item-color="primary" multiple
        :label="t('publicHome.telemetryContent.deviceSelection')" v-model="selectedDevices"></v-select>
      <br />
      <!-- param selection -->
      <v-select :items="parameters" multiple :label="t('publicHome.telemetryContent.parameterSelection')"
        v-model="selectedParameters" item-title="alias" item-value="id" item-color="primary" return-object>
        <template #prepend-item>
          <v-list-item ripple @mousedown.prevent @click="selectAllParameters">
            <v-list-item-action>
              <v-icon :color="selectedParameters.length === parameters.length ? 'primary' : ''">{{
                selectedParameters.length === parameters.length ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'
                }}</v-icon>
              <v-list-item-title>Select All</v-list-item-title>
            </v-list-item-action>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>
      </v-select>
      <br />
      <!-- tabs -->
      <v-tabs v-model="activeTab" align-tabs="center">
        <v-tab value="range-tab">
          <div class="tabs-title">Select Range</div>
        </v-tab>
        <div class="tabs-divider">or</div>
        <v-tab value="calendar-tab">
          <div class="tabs-title">Use Calendar</div>
        </v-tab>
      </v-tabs>

      <v-tabs-window v-model="activeTab">
        <!-- tab 1 -->
        <v-tabs-window-item value="range-tab" class="tabs-content">
          <v-select :items="dateRangeOptions" v-model="selectedDateRange" item-title="label" item-value="age"
            label="Select Time Range"></v-select>
        </v-tabs-window-item>

        <!-- tab 2 -->
        <v-tabs-window-item value="calendar-tab" class="tabs-content">
          <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" min-width="290px">
            <template #activator="{ props }">
              <v-text-field v-model="calendarDateRangeInput" label="Calendar Date Selection" placeholder="YYYY/MM/DD"
                prepend-icon="mdi-calendar" readonly v-bind="props"></v-text-field>
            </template>

            <v-date-picker color="primary" v-model="calendarDateRangePicker" @update:model-value="updateCalendarDateRange" no-title
              position="bottom" :min="minDate" :max="maxDate" multiple="range" :show-current="false" hide-header="true">
              <template #actions>
                <v-btn class="bg-primary" block @click="clearDateRange">
                  Clear
                </v-btn>
              </template>

            </v-date-picker>
          </v-menu>
        </v-tabs-window-item>
      </v-tabs-window>
    </v-card-text>
    <v-btn :disabled="requestDataButtonDisabled" block @click="submitTableau" class="bg-primary authButton">{{
      t("publicHome.telemetryContent.requestData") }}</v-btn>
  </div>
</template>

<script lang="ts">
import { useWDCStore } from '@/store';
import { defineComponent, ref, computed, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import moment from 'moment';


interface Parameter {
  id: string;
  alias: string;
  dataType: string;
}

interface Device {
  device_id: string;
  metadata: {
    friendlyName: string;
  };
}

export default defineComponent({
  name: 'TelemetryContent',
  props: {
    authenticated: {
      type: Boolean,
      default: false
    },
  },
  setup(props) {
    const { t } = useI18n({ useScope: 'global' })

    const wdcStore = useWDCStore()

    const activeTab = ref('range-tab');
    const dateRangeOptions = [
      { age: 1, label: t('publicHome.dateRange.lastDay') },
      { age: 7, label: t('publicHome.dateRange.lastWeek') },
      { age: 14, label: t('publicHome.dateRange.lastTwoWeeks') },
      { age: 30, label: t('publicHome.dateRange.lastMonth') }
    ];
    const selectedDateRange = ref(); // default is undefined can become one of the age values in dateRangeOptions
    const calendarDateRangeInput = ref<Array<string>>([]);
    const calendarDateRangePicker = ref<Array<Date>>([]);
    const devices = ref<Array<Device>>([]);
    const selectedDevices = ref<Array<any>>([]);
    const parameters = ref<Array<Parameter>>([
      { id: 'model', alias: 'Model', dataType: window.tableau.dataTypeEnum.string },
      { id: 'serial', alias: 'Serial', dataType: window.tableau.dataTypeEnum.string },
      { id: 'is_public', alias: 'Is Public', dataType: window.tableau.dataTypeEnum.bool },
      { id: 'is_indoor', alias: 'Is Indoor', dataType: window.tableau.dataTypeEnum.bool },
      { id: 'location', alias: 'Location', dataType: window.tableau.dataTypeEnum.geometry },
      { id: 'mcpm1x0', alias: 'PM 1.0', dataType: window.tableau.dataTypeEnum.int },
      { id: 'mcpm2x5', alias: 'PM 2.5', dataType: window.tableau.dataTypeEnum.int },
      { id: 'mcpm2x5_aqi', alias: 'PM 2.5 AQI', dataType: window.tableau.dataTypeEnum.int },
      { id: 'mcpm4x0', alias: 'PM 4.0', dataType: window.tableau.dataTypeEnum.int },
      { id: 'mcpm10', alias: 'PM 10', dataType: window.tableau.dataTypeEnum.int },
      { id: 'mcpm10_aqi', alias: 'PM 10 AQI', dataType: window.tableau.dataTypeEnum.int },
      { id: 'ncpm0x5', alias: 'PM 0.5 Num. Conc.', dataType: window.tableau.dataTypeEnum.int },
      { id: 'ncpm1x0', alias: 'PM 1.0 Num. Conc.', dataType: window.tableau.dataTypeEnum.int },
      { id: 'ncpm2x5', alias: 'PM 2.5 Num. Conc.', dataType: window.tableau.dataTypeEnum.int },
      { id: 'ncpm4x0', alias: 'PM 4.0 Num. Conc.', dataType: window.tableau.dataTypeEnum.int },
      { id: 'ncpm10', alias: 'PM 10 Num. Conc.', dataType: window.tableau.dataTypeEnum.int },
      { id: 'tpsize', alias: 'Typical Particle Size', dataType: window.tableau.dataTypeEnum.float },
      { id: 'temperature', alias: 'Temperature (C)', dataType: window.tableau.dataTypeEnum.float },
      { id: 'rh', alias: 'Relative Humidity', dataType: window.tableau.dataTypeEnum.int },
      { id: 'co2_ppm', alias: 'CO2', dataType: window.tableau.dataTypeEnum.int },
      { id: 'ch2o_ppb', alias: 'CH2O', dataType: window.tableau.dataTypeEnum.int },
      { id: 'baro_inhg', alias: 'Pressure', dataType: window.tableau.dataTypeEnum.float },
      { id: 'co_ppm', alias: 'CO', dataType: window.tableau.dataTypeEnum.int },
      { id: 'so2_ppb', alias: 'SO2', dataType: window.tableau.dataTypeEnum.int },
      { id: 'o3_ppb', alias: 'O3', dataType: window.tableau.dataTypeEnum.int },
      { id: 'no2_ppb', alias: 'NO2', dataType: window.tableau.dataTypeEnum.int },
      { id: 'voc_mgm3', alias: 'TVOC', dataType: window.tableau.dataTypeEnum.int }
    ]);
    const selectedParameters = ref<Parameter[]>([]);
    const menu = ref(false);


    const minDate = computed(() => {
      let minDate = new Date(Date.now())
      minDate = new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate())
      minDate.setDate(minDate.getDate() - 30)

      return minDate.toISOString()
    });

    const maxDate = computed(() => {
      let maxDate = new Date(Date.now())
      maxDate = new Date(maxDate.getFullYear(), maxDate.getMonth(), maxDate.getDate())

      return maxDate.toISOString()
    });

    const requestDataButtonDisabled = computed(() => {
      return selectedDevices.value.length === 0 ||
        selectedParameters.value.length === 0 ||
        (activeTab.value === 'calendar-tab' && calendarDateRangeInput.value.length < 2) ||
        (activeTab.value === 'range-tab' && selectedDateRange.value === null);
    });

    const clearDateRange = () => {
      calendarDateRangeInput.value = [];
      calendarDateRangePicker.value = [];
    };


    // Function to parse date into YYYY-MM-DD format
    const parseDate = (date: Date) => {
       return moment.utc(date).format('YYYY-MM-DD') // setting utc timezone avoids local timezone date adjustment
    }

    const updateCalendarDateRange = (newRange: Array<Date>) => {
      if (newRange.length >= 2) {
        const newDateRange = newRange
        const startDate = newDateRange[0];
        const endDate = newDateRange[newDateRange.length - 1];
        if (isNaN(startDate.getDate()) || isNaN(endDate.getDate())) {
          console.error("Invalid dates:", startDate, endDate);
          return;
        }

        if (startDate > endDate) {
          newRange.reverse();
        }

        calendarDateRangeInput.value = [
          parseDate(newRange[0]),
          parseDate(newRange[newRange.length - 1])
        ];
      } else {

        calendarDateRangeInput.value = [
          parseDate(newRange[0]),
        ];
      }
      calendarDateRangePicker.value = newRange;

    };

    const getDevices = async () => {
      await wdcStore.getDevices(window.tableau);
      devices.value = wdcStore.devices || [];
    };

    const submitTableau = () => {
      const connectionData = {
        devices: selectedDevices.value,
        parameters: selectedParameters.value,
        dateRange: Array<string>(),
        age: Number()
      };

      if (activeTab.value === 'calendar-tab') {
        connectionData.dateRange = calendarDateRangeInput.value;
      } else {
        connectionData.age = selectedDateRange.value;
      }

      window.tableau.connectionData = JSON.stringify(connectionData);
      window.tableau.submit();
    };

    const selectAllParameters = () => {
      if (selectedParameters.value.length === parameters.value.length) {
        selectedParameters.value = [];
      } else {
        selectedParameters.value = [...parameters.value];
      }
    };

    watch(() => props.authenticated, (newValue) => {
      if (newValue) {
        getDevices();
      }
    }, { immediate: true });

    onMounted(() => {
      const tableau = window.tableau;
      if (tableau.connectionData) {
        const connectionData = JSON.parse(tableau.connectionData);
        selectedDevices.value = connectionData.devices;
        selectedParameters.value = connectionData.parameters;
        if (connectionData.dateRange) {
          activeTab.value = 'calendar-tab';
          calendarDateRangeInput.value = connectionData.dateRange;
        } else {
          selectedDateRange.value = connectionData.age;
        }
      }
    });

    return {
      activeTab,
      dateRangeOptions,
      selectedDateRange,
      calendarDateRangeInput,
      calendarDateRangePicker,
      devices,
      selectedDevices,
      parameters,
      selectedParameters,
      minDate,
      maxDate,
      requestDataButtonDisabled,
      clearDateRange,
      getDevices,
      updateCalendarDateRange,
      submitTableau,
      selectAllParameters,
      t,
      menu
    };
  }
});
</script>

<style scoped lang="scss">
@import '@/assets/shared.scss';

.v-list-item {
  padding-inline: 24px !important;
}

.v-list-item-title {
  padding-left: 8px;
}

.v-picker--date::v-deep .v-btn--active {
  background-color: $color-main-primary !important;
}

.clearDateRangeButton {
  min-width: 0px !important;
  margin: 0px 16px 16px 16px;
}

.tabs-content {
  margin-top: 16px;
  height: 70px;
}

.tabs-divider {
  color: rgb(0, 0, 0, 0.46);
  font-size: 12px;
  line-height: 46px;
  margin-left: 24px;
  margin-right: 24px;
  font-weight: 500;
}

.tabs-title {
  // width: 160px;
}
</style>
<style lang="scss">
@import '@/assets/shared.scss';

.v-list-item--highlighted::before {
  opacity: 0 !important;
}
</style>
