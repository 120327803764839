
<template>
  <v-app>
    <v-main class="background-image">
      <v-container class="ma-0 pa-0" fluid>
        <router-view class="main-content" />
      </v-container>
    </v-main>
  </v-app>
</template>

<style scoped lang="scss">
@import '@/assets/global-variables.scss';

.main-content {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.footer {
  opacity: 0.3;
  padding: 0.4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-height: 37px;
}

main {
  background: $background-gradient;
}

.background-image {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: black url('https://cdn.tsilink.com/images/background_city.jpg') no-repeat;
  background-size: cover;
}

</style>
