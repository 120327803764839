import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

export const vuetify = createVuetify({
  theme: {
    themes: {
      light: {
        colors: {
          primary: '#00aeef',
          accent: '#FA1717',
          secondary: '#1A1919',
          info: '#00aeef',
          warning: '#F48123',
          error: '#FA1717',
          success: '#4FB74A'
        }
      }
    }
  },
  components,
  directives
})
